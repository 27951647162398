/**
* Generated automatically at built-time (2025-04-01T05:18:41.903Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "komfur-black-friday",templateKey: "sites/95-4fb9c31b-ae11-4765-ad31-1a9a8574799b"};